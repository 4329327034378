body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
  margin-bottom: 120px; /* Updated margin to the bottom of the page */
}

h1, h2 {
  font-family: 'Instrument Serif', serif;
  font-weight: normal;
}

h1 {
  font-size: 80px;
  margin-bottom: 24px;
  letter-spacing: -2px;
}

/* Media queries */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 40px; /* Reduced font size for mobile */
    letter-spacing: -1px;
  }
}

h2 {
  margin: 8px 0 0;
  font-size: 32px;
  line-height: 1.2;
}

.content {
  margin-bottom: 40px;
  max-width: 100%;
}

.wishlist {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 48px 32px; /* 48px for row gap, 32px for column gap */
}

.item {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item img {
  width: 100%;
  height: 280px; /* Set a fixed height */
  object-fit: cover; /* Maintain aspect ratio and cover the area */
  border-radius: 8px;
  border: 1px solid #e0e0e0; /* Soft 1px border */
}

.item p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.button-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.item-button {
  background-color: transparent;
  color: #000;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.item-button::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #000;
}

.item-button:hover {
  opacity: 0.7;
}

.item-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  text-decoration: line-through;
}

.signature {
  width: 150px;
  margin: 20px 0;
}

/* Media query for desktop */
@media screen and (min-width: 768px) {
  .content {
    max-width: 60%;
  }
  
  .wishlist {
    grid-template-columns: repeat(4, 1fr);
    gap: 48px 32px; /* 48px for row gap, 32px for column gap */
  }
}

/* Modify the existing .item-crossed-out class */
.item-crossed-out {
  opacity: 0.7; /* Slightly increase opacity to make it less faded */
}

/* Remove the ::after pseudo-element that created the line */
.item-crossed-out::after {
  content: none;
}

/* Only apply line-through to text elements */
.item-crossed-out h2,
.item-crossed-out p {
  text-decoration: line-through;
}